@CHARSET "UTF-8";


.bandeauClass
{
	
	width : 100% ;
	/*height : 100px;*/
	background-color : black;
	float : left ;
	/*background-image : url(../../data/appData/appImages/entete/pizza.jpeg)  ;*/
	/*background-image : url(../appImages/back/back3.png)  ;*/
 	background-repeat: round; 
	text-align : left ;
	color : white ;
	/*
	position        : fixed;
	z-index: 4;
	*/
}
.logoImage1
{
	width : 100%;
	float : left ;
}
.logoImage2
{
	width : 100%;
	float : left ;
	/*border : 1px solid red ;*/
}


div#logoDiv
	{
		float : left;
		/*width : 180px;*/
/* 		margin-top : 3px ;  */
/* 		margin-bottom : 3px ;  */
/* 		margin-left : 10px ; */
}
.logoDiv2{
	float : left ;
	/*width : 400px ;*/
}


.addDiv
{
	float : left ;
	max-width : 310px ;
	color : white ;
	font-size : 15px;
	font-weight : bold ;
/* 	margin-top : 100px ; */
	background-color : black ;
	padding : 5px ;
	margin-top : 5px ;
	margin-left : 5px ;
	border-radius : 5px ;
}

.telDiv
{
	float : left ;
	max-width : 310px ;
	color : white ;
	font-size : 16px;
	font-weight : bold ;
/* 	margin-top : 100px ; */
	background-color : black ;
	padding : 5px ;
	margin-top : 5px ;
	margin-left : 5px ;
	
	border-radius : 5px ;
}

.addTel
{
	float:left ;
/* 	width : 350px ; */
/* 	height : 100px ; */
	padding-bottom : 5px ;
	
	
}

div#headerRightDiv
	{
		float : right;
		width : 180px;
		margin : 3px ;
		height :100px ;
		font-size : 1.1em ;
		margin-right : 5px;
		/*border : 1px solid red ;*/
		
	}









.welcomeMsgDiv
{
	width : 100% ;
	font-size : 1.1em;
	font-weight : bold ;
	text-align : center;
	color : red ;
	margin : auto ;
	float : left ;
	background-color : #202020;
}

.retDivClass
{
	width : 100% ;
	float:left ;
	text-align : left ;
	color : red ;
	font-weight : bold ;
	font-size : 1.2em ;
	margin-top : 20px ;
}	


	

@media screen and ( min-width : 1200px ) 
{
	.logoDiv2ClassResp{
		width : 450px;
	}
	.logoDivClassResp
	{
		width : 150px;
	}
}

@media screen and ( min-width : 800px ) and ( max-width : 1200px )
{
	.logoDiv2ClassResp{
		width : 450px;
	}
	.logoDivClassResp
	{
		width : 150px;
	}
}

@media screen and ( min-width : 600px ) and ( max-width : 800px )
{
	.logoDiv2ClassResp{
		width : 400px;
	}
	.logoDivClassResp
	{
		width : 150px;
	}
}

@media screen and ( min-width : 510px ) and ( max-width : 600px )
{
	.logoDiv2ClassResp{
		width : 350px;
	}
	.logoDivClassResp
	{
		width : 120px;
	}
	
}

@media screen  and ( min-width : 400px ) and ( max-width : 510px )
{
	.logoDiv2ClassResp{
		width : 290px;
	}
	.logoDivClassResp
	{
		width : 110px;
	}
	
}

@media screen  and ( max-width : 400px )
{
	.logoDiv2ClassResp{
		width : 70%;
	}
	.logoDivClassResp
	{
		width : 30%;
	}
	
}

