
div#footer1Id
{
	width : 100% ;
	/*height : 150px ;*/
	background-color : black;
	margin : auto ;
	margin-top: 30px ;
	float : left ;
	/*margin-top : 30px ;*/
}

div#ConnectionDiv
{
	float : right ;
	width : 60px ;
	text-align : right ;
	color : white ;
	/*height : 100px ;*/
	/*background-color : red ;*/
}
div#connexionDivMarg
{
	float : right ;
	width : 70px ;
	height : 70px ;
	/*background-color : green ;*/
	margin-right : 10px ;
	margin-top : 5px ;
	font-size : 1.1em ;
	border : 1px solid white ;
	border-radius : 35px;
	text-align : center ;
	vertical-align : top;
 	line-height : 15px ; 
	
}

div#connexionDivMarg a
{
	text-decoration : none ;
	color : grey ;
}

/* .connexionInputClass */
/* { */
/* 	width : 150px ; */
/* 	/*mardin-top : 10px ;*/ */
	
/* } */
/* .connexionButtonClass */
/* { */
/* 	width : 150px ; */
/* 	background-color : red ; */
/* 	height : 30px ; */
/* 	border-radius : 5px ; */
/* 	color : white ; */
/* 	/*mardin-top : 10px ;*/ */
	
/* } */
/* .connexionInputDivClass */
/* { */
/* 	width : 180px; */
/* 	/*background : blue ;*/ */
/* 	text-align : right ; */
/* 	margin-top : 5px ; */
/* } */

span#refDiv
{
	background-color : grey ;
	color : #202020 ;
	float : left ;
	width : 100%;
}

 
 span#refDiv a {
 text-decoration: none;
 color : black ;
 }
 
 div#rsDiv
 {
 	margin-top : 50px ;
 	text-align : center ;
 }
 
 .footerMenuDiv
 {
 	float : left ;
 	width : 100% ;
 	color : grey ;
 	margin-top : 30px ;
 	margin-bottom : 30px ;
 }
 .footerOneMenuDiv
 {
 	float : left;
 	
 	text-align : center ;
 }
 .footerOneMenuDivMarge
 {
 	margin : auto ;
 	width : 60px;
 	text-align : left ;
 	font-weight : bold ;
 	font-size : 1.2em;
 }
 .footerOneMenuDivMarge a 
 {
 	text-decoration: none;
 	color : grey ;
 }

 .copyrightDiv
 {
 	color : grey ;
 	float : left ;
 	text-align : center ;
 	width : 100% ; 
 }
 
 .copyrightDiv a
 {
 	text-decoration : none ;
 	color : grey;
 }
 
 .copyrightDiv a:hover
 {
 	color : white ;
 }
 
@media screen and (min-width : 600px )
{
	.footerOneMenuDivResp
	{
		width :25%;
	}
}

@media screen and (min-width : 200px ) and (max-width : 600px)
{
	.footerOneMenuDivResp
	{
		width :50%;
	}
}

@media screen and (max-width : 200px )
{
	.footerOneMenuDivResp
	{
		width :100%;
	}
}
 
 
