

.bodyClass
{
	
	padding : 0px ;
	margin : 0px;
	text-align : center ;
	/*Background-color : black ;*/
	
}

a:link
{
	text-decoration : none ;
}

div#beforFooter
{
	height : 30px ;
	width : 100% ;
	float : left ;
}






