.content1Div
{
	width : 100% ;
	/*height : 500px;*/
	/*background-image : url(../appImages/back/background1.jpg);*/
	float : left ;
/* 	background-color : white; */
	background-color : black;
}

.centePerCentWidthAndFloatContainer{
	width : 100% ;
	float : left ;
	margin-top: 20px ;
}

.rowContainer
{
	margin : auto;
	
	
}

.cartDiv
{
	text-align : center ;
	
	width : 320px;
	height : 304px;
/* 	border : 1px solid white; */
	
	
	/*border : 1px solid red ;*/
	
}

.carteDivMarge
{
	border-bottom : 4px solid #f3ba43 ;
	border-left : 4px solid #f3ba43 ;
	border-right : 4px solid #f3ba43 ;
	margin-bottom : 10px ;
	margin-right : 10px ;
	margin-left : 10px ;
	border-radius : 100px 100px 20px 20px ;
	background-color : white ;
	width : 300px;
	height : 230px;
	background-color : #303030 ;
}

.carteImageClass
{
	width : 100% ;
	border-radius : 100px 100px 20px 20px ;
/* 	border-radius : 10px ; */
}

.carteLabelClass
{
	width : 300px ;
	text-align : center ;
	font-size : 1.2em ;
	font-weight : bold ;
	color : black  ;
	height: 35px ;
	background-color : #f3ba43 ;
	margin-top : 10px ;
	margin-right : 10px ;
	margin-left : 10px ;
	border-radius : 15px 15px 0px 0px;
}







@media screen and (min-width : 1000px)
{
	.rowContainerResp
	{
		width : 80%;
	}
	.cartDivResp 
	{ 
 		float : left ;
 	} 
}

@media screen and (min-width : 568px) and (max-width : 1000px)
{
	.rowContainerResp
	{
		width : 85%;
	}
 	.cartDivResp 
	{ 
 		float : left ;
 	} 
}


@media screen and (min-width : 500px) and (max-width : 568px)
{
	.rowContainerResp
	{
		width : 98%;
	}
 	.cartDivResp 
	{ 
 		float : left ;
 	} 
}

@media screen and (max-width : 500px)
{
	.rowContainerResp
	{
		width : 98%;
		
	}
	.cartDivResp 
	{ 
 		margin : auto ;
 	} 
}




