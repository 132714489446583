@CHARSET "UTF-8";

.infoDivSous
{ 
	background-color : white ;
	margin : auto ;
	margin-top : 20px;
	border-radius : 10px 10px 0 0 ;
	border : 2px solid black ;
	font-size : 1em ;
	text-align : left ;
	text-decoration : justify ;
	background-color : black ;
} 
.infoTitle
{
	background-color : black ;
	color : #ec8923 ;
	width : 100% ;
	/*height : 50px ;*/
	border-radius : 10px 10px 0 0 ;
	text-align : center ;
	font-size : 1.3em;
	font-weight : bold ;
}

.infoPhotoClass
{
	width : 100% ;
	margin : auto ;
	/*margin-buttom : 0px;*/
}

.parInfoClass
{
	
	margin : 7px;
	
	text-align: justify;
    text-justify: inter-word;
}

.infoDivTextDiv
{
	float : left ;
	width : 100% ;
	background-color : white;
	text-decoration : justify ;
}


div#evaluationDivScrollId
{
	width : 100%;
	height : 270px ;
	background-color : white ;
	margin : auto ;
	
	text-align : left ;
	
	/*border-radius : 10px 10px 0 0 ;*/
	/*background-color : #E0E0E0 ;*/
	overflow: auto;
}









.infoDivBorder
{
	
}




@media screen and (min-width : 1200px)
{
	
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
	}
	.infoDiv
	{
		float :left ;
		width : 25%;
		/*height : 350px ;*/
	}
	.infoDivSousResp
	{
		width : 90% ;
		/*height : 350px;*/
		
/* 		background-color : white ; */
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 2px solid black ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
	}
	
	
}



@media screen and (min-width : 900px) and (max-width : 1200px) 
{
	
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
	}
	.infoDiv
	{
		float :left ;
		width : 25%;
	}
	.infoDivSousResp
	{
		width : 90% ;
		/*height : 350px;*/
		
/* 		background-color : white ; */
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 2px solid black ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
	}
	
	
}



@media screen and (min-width : 500px) and (max-width : 900px) and (orientation : landscape)
{
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
		margin-top : 10px ;
		/*background-color : red ;*/
		
	}
	.infoDiv
	{
		float :left ;
		width : 50%;
		/*height : 350px ;*/
		margin-top : 30px ;
	}
	.infoDivSousResp
	{
		width : 90% ;
		/*height : 350px;*/
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 1px solid grey ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
	}
	
}



@media screen and (min-width : 500px) and (max-width : 900px) and (orientation : portrait)
{
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
		margin-top : 10px ;
		/*background-color : red ;*/
		
	}
	.infoDiv
	{
		float :left ;
		width : 50%;
		/*height : 350px ;*/
		margin-top : 30px ;
	}
	.infoDivSousResp
	{
		width : 90% ;
		/*height : 350px;*/
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 1px solid grey ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
	}
	
	
}


@media screen and (min-width : 250px) and (max-width : 500px) 
{
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
		/*margin-top : 10px ;*/
		/*background-color : red ;*/
		
	}
	.infoDiv
	{
		float :left ;
		width : 100%;
		/*height : 350px ;*/
		margin-top : 20px ;
	}

	.infoDivSousResp
	{
		width : 99% ;
		/*height : 350px;*/
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 1px solid grey ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
/* 		float : left ; */
	}
	
	
}


@media screen and (min-width : 10px) and (max-width : 250px) 
{
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
		margin-top : 10px ;
		/*background-color : red ;*/
		
	}
	.infoDiv
	{
		float :left ;
		width : 100%;
		/*height : 350px ;*/
		margin-top : 20px ;
	}

	.infoDivSousResp
	{
		width : 99% ;
/* 		height : 350px; */
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 1px solid grey ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
/* 		float : left ; */
	}
	
	
}

