.mapClass{
    float : left ;
    width : 100% ;
    height: 600px ;
    text-align : center;
    margin-top : 40px ;
    
}

.mapClassMarge{
    
    width : 100% ;
    height: 550px ;
    margin : auto ;
    /*border : 5px solid black ;*/
    /*border-bottom : 5px solid black ;*/
    text-align: center;
}
