@CHARSET "UTF-8";

.menuDivId
{
	background-color : #ec8923;
	width : 100% ;
	float : left ;
	text-align : left ;
	float : left ;
}

/*
.menuItemDiv a 
{
	text-decoration : none ;
	color : white ;
}
*/


.menuItemDiv
{
	color : #CC5500 ;
	float : left ;
	height : 35px ;
	text-align : left ;
	font-size : 1.2em;
	margin-top :9px ;
	cursor: pointer;
	
}



.divMenuIconBlack
{
	width : 100% ;
	float : left ;
	height:30px ;
	background-color : #CC5500 ;
}

.divMenuIconGrey
{
	width : 100% ;
	float : left ;
	height:30px ;
	background-color : black ;
}

span#hiddingMenuDivForMenu
{
	
	float : right ;
	font-size : 1.5em;
	color : white ;
}

@media screen and (min-width : 600px) {
	.menuItemDivResp{
		width : 25% ;
	}
	.divMenuIconResp{
		display: none;
	}
}

@media screen and (max-width : 600px) {
	.menuItemDivResp{
		width : 100% ;
	}
	.divMenuIconResp{
		display: block;
	}
}





